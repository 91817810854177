import React, { ReactNode, useEffect, useMemo, useState } from "react"
import { WidgetProps } from "@rjsf/core"
import { Form } from "react-bootstrap"

const RadioSelectWidget = <T extends any>(
  props: WidgetProps & {
    providedOptions: T[]
    getValue: (option: T) => string | null
    getLabel: (option: T) => string | ReactNode
  }
) => {
  const [selected, setSelected] = useState<string | null>(null)

  return (
    <div>
      {props.providedOptions.map((opt, i) => (
        <Form.Check key={`RadioSelect-${props.id}-option-${i}`}>
          <Form.Check.Input
            disabled={props.disabled}
            type="radio"
            name="radioSelectWidget"
            checked={selected === props.getValue(opt)}
            onChange={() => {
              setSelected(props.getValue(opt))
              props.onChange(props.getValue(opt))
            }}
          />
          <Form.Check.Label>{props.getLabel(opt)}</Form.Check.Label>
        </Form.Check>
      ))}
    </div>
  )
}
export default RadioSelectWidget
