import React from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import "../style.scss"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container } from "react-bootstrap"

import ConfirmAppointment from "../components/AppointmentConfirmation"
const ConfirmationPage = () => {
  return (
    <Layout sectionTitle={"Bevestiging afspraak"}>
      <SEO title="Bevestiging afspraak" />
      <Container>
        <ConfirmAppointment />
      </Container>
    </Layout>
  )
}

export default ConfirmationPage
