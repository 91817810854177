import React, { useEffect, useState } from "react"
import localization from "moment/locale/nl-be"

import Form, { WidgetProps } from "@rjsf/core"
import moment from "moment"
import { Button, Card } from "react-bootstrap"
import RadioSelectWidget from "./RadioSelectWidget"
import { useLocation } from "@reach/router"

import queryString from "query-string"
import { gql, useMutation, useQuery } from "@apollo/client"
const SELECT_APPOINTMENT_OPTION_BY_ID = gql`
  mutation SelectAppointmentFromProposal($appointmentId: String!) {
    selectAppointmentFromProposal(appointmentId: $appointmentId)
  }
`

const PUBLIC_APPOINTMENT_PROPOSAL = gql`
  query GetPublicAppointmentProposal($proposalId: String!) {
    getAppointmentInfoPublic(proposalId: $proposalId) {
      id
      fname
      name
      proposedAppointments {
        date
        id
      }
    }
  }
`

const ConfirmAppointment: React.FC = () => {
  const { search } = useLocation()
  // Todo add token
  const { id } = queryString.parse(search)
  if (!id) return <div>Shit happens</div>
  return (
    <div style={{ margin: 10, padding: 10 }}>
      <Card>
        {/*<CardContent>*/}
        <ConfirmAppointmentBody proposalId={id} />
        {/*</CardContent>*/}
      </Card>
    </div>
  )
}

const ConfirmAppointmentBody = ({ proposalId }: { proposalId: string }) => {
  const { data, loading } = useQuery(PUBLIC_APPOINTMENT_PROPOSAL, {
    variables: { proposalId },
  })
  const [backup, setBackup] = useState<string>()

  const [selectAppointment, selectMutResult] = useMutation(
    SELECT_APPOINTMENT_OPTION_BY_ID
  )
  const [options, setOptions] = useState<any>()
  const getData = () => {
    const findya = options.proposedAppointments.find(
      ({ id }: any) => id === backup
    )
    return (
      moment(findya.date)
        // @ts-ignore
        .locale("nl", localization)
        .format("D MMMM YYYY [om] HH:mm")
    )
  }
  useEffect(() => {
    if (data && options !== data.getAppointmentInfoPublic)
      setOptions(data.getAppointmentInfoPublic)
  }, [data, options])

  if (selectMutResult.loading) {
    return <p>Jouw afspraak wordt bevestigd! Even geduld...</p>
  }
  if (loading) {
    return <div>Afspraak laden... Even geduld, dit kan soms even duren...</div>
  }
  if (!options) {
    return <p>Oeps, pagina niet gevonden</p>
  }
  let body
  if (selectMutResult.data) {
    body = (
      <p>
        Super! Jouw afspraak is succesvol bevestigd. Een bevestigingsmail is
        verstuurd naar jouw emailadres.
      </p>
    )
  } else if (selectMutResult.error) {
    console.log(backup)
    body = (
      <p>
        Oeps, dit ging niet zoals verwacht. Verstuur nog een mail dankzij{" "}
        <a
          href={`mailto:${
            process.env.GATSBY_EMAIL_RECEIVER
          }?subject=${encodeURI("Bevestiging afspraak")}&body=${encodeURI(
            getData()
          )}`}
        >
          deze link
        </a>
        .<br />
        Onze excuses voor dit ongemak.
        <br />
        <br />
        Vriendelijke groeten, <br />
        Het Orthodontisch team
      </p>
    )
  } else {
    body = (
      <>
        <p>
          Bevestig je afspraak door een van de opties te kiezen. Moest geen van
          volgende opties mogelijk zijn, neem dan terug contact op via mail voor
          een nieuw voorstel.
        </p>
        <Form<{ select: string }>
          onSubmit={({ formData }) => {
            selectAppointment({
              variables: { appointmentId: formData.select },
            })
            setBackup(formData.select)
          }}
          uiSchema={{
            select: {
              "ui:widget": (props: WidgetProps) => (
                <RadioSelectWidget
                  {...props}
                  getLabel={option =>
                    moment(option!.date).format("D MMMM YYYY [om] H:mm")
                  }
                  getValue={option => option!.id!}
                  providedOptions={options.proposedAppointments!}
                />
              ),
            },
          }}
          schema={{
            title: "Bevestig afspraak",
            required: ["select"],
            type: "object",
            properties: {
              select: {
                type: "string",
                title: "Mogelijke opties",
              },
            },
          }}
        >
          <Button type={"submit"}>{"Kies deze afspraak"}</Button>
        </Form>
      </>
    )
  }
  return (
    <div>
      <p>
        {`Beste ${options.fname} ${options.name},`}
        <br />
        <br />
      </p>
      {body}
    </div>
  )
}
export default ConfirmAppointment
